<template>
  <v-content>
    <!--<v-breadcrumbs :items="items" large></v-breadcrumbs>-->

    <v-snackbar
      :timeout="callbackResponse.timeout"
      color="success"
      top
      v-model="snackbar"
    >
      {{ callbackResponse.message }}
      <v-btn @click="snackbar = false" dark text>Close</v-btn>
    </v-snackbar>

    <v-dialog max-width="500px" v-model="dialogEditRoom">
      <editForm
        :editData="editedDataService.hotelCustomerServiceID"
        :success="success"
        @formResponse="onResponse"
        v-if="dialogEditRoom"
      />
    </v-dialog>
    <v-dialog max-width="500px" v-model="dialogDelete">
      <deleteListData
        :deleteFormData="deleteFormData"
        @formResponse="onResponseDelete"
      ></deleteListData>
    </v-dialog>

    <v-dialog max-width="500px" v-model="dialogCreateRoom">
      <template v-slot:activator="{ on }">
        <v-btn
          :success="success"
          class="ma-4 float-right"
          color="primary"
          dark
          v-on="on"
          >{{ $t("add_service") }}</v-btn
        >
      </template>
      <createForm :success="success" @formResponse="onResponse" />
    </v-dialog>
    <v-container fluid>
      <vue-good-table
        :columns="columnsRoom"
        :isLoading.sync="isLoading"
        :pagination-options="{ enabled: true, perPageDropdown: [50, 100, 200] }"
        :rows="roomRows"
        :totalRows="totalRecords"
        @on-column-filter="onColumnFilter"
        @on-page-change="onPageChange"
        @on-per-page-change="onPerPageChange"
        @on-sort-change="onSortChange"
        mode="remote"
      >
        <template slot="table-column" slot-scope="props">
          <span>
            {{ $t(props.column.label) }}
          </span>
        </template>
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'actions'">
            <v-icon @click="editItemRoom(props.row)" class="mr-2" small
              >edit</v-icon
            >
            <v-icon @click="deleteItemRoom(props.row)" small>delete</v-icon>
          </span>
        </template>
      </vue-good-table>
    </v-container>
  </v-content>
</template>

<script>
import axios from "axios";
import createForm from "./CreateService";
import editForm from "./EditService";
import deleteListData from "@/components/deleteModal";
import { parseJwt } from "@/mixins/parseJwt";

export default {
  name: "List",
  mixins: [parseJwt],
  watch: {
    dialog(val) {
      val || this.close();
    },
    success: {
      handler: function(val, oldVal) {
        console.log(val, oldVal);
        this.loadItemsRoom();
      },
      deep: true,
    },
  },
  components: {
    createForm,
    editForm,
    deleteListData,
  },
  data() {
    return {
      callbackResponse: {
        timeout: 6000,
      },
      snackbar: false,
      success: false,
      dialogCreateRoom: false,
      dialogEditRoom: false,
      dialogDelete: false,
      panel: [0],
      deleteFormData: {},
      popout: true,
      isLoading: false,
      columnsRoom: [
        {
          label: "s_no",
          field: "countIndex",
        },
        {
          label: "customer_service_id",
          field: "hotelCustomerServiceID",
        },
        {
          label: "customer_service",
          field: "hotelCustomerService",
        },
        {
          label: "service_description",
          field: "hotelServiceDescription",
        },
        {
          label: "service_rate",
          field: "hotelServiceRate",
        },
        {
          label: "actions",
          field: "actions",
          sortable: false,
        },
      ],
      items: [
        {
          text: "Settings",
          to: "/HomeStaySettings",
          disabled: false,
          exact: true,
        },
        {
          text: "Service Setting",
          disabled: true,
        },
      ],
      roomRows: [],
      totalRecords: 0,
      serverParamsRoom: {
        search: "",
        columnFilters: {},
        sort: [
          {
            field: "countIndex",
            type: "asc",
          },
        ],
        page: 1,
        perPage: 50,
      },

      user: [],
      editedIndex: -1,
      editedDataService: {},
    };
  },

  mounted() {
    this.loadItemsRoom();
    this.filterData();
  },
  destroyed() {
    console.log(this); // There's practically nothing here!
  },
  methods: {
    onResponse(data) {
      if (data) {
        this.snackbar = true;
        this.dialogCreateRoom = false;
        this.dialogEditRoom = false;
        this.callbackResponse = data;
        this.loadItemsRoom();
      }
    },
    onResponseDelete(data) {
      if (data) {
        this.snackbar = true;
        this.callbackResponse = data;

        this.dialogDelete = false;
        this.loadItemsRoom();
      } else {
        this.dialogDelete = false;
      }
    },
    editItemRoom(props) {
      this.editedDataService = props;
      this.dialogEditRoom = true;
      // this.dialogEditFare = true;
    },

    deleteItemRoom(props) {
      console.log(props);
      this.dialogDelete = true;
      this.deleteFormData.url =
        "Hotel/DeleteCustomerServiceAsync/" + props.hotelCustomerServiceID;
      this.loadItemsRoom();
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedData = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    searchInput() {
      this.loadItemsRoom();
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItemsRoom();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItemsRoom();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.loadItemsRoom();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItemsRoom();
    },

    async filterData() {},
    // load items is what brings back the rows from server
    async loadItemsRoom() {
      console.log(this.tokenData);
      // eslint-disable-next-line no-console
      let param = {
        PageSize: this.serverParamsRoom.perPage,
        PageNo: this.serverParamsRoom.page,
        OrderType: this.serverParamsRoom.sort[0].type,
        OrderBy: this.serverParamsRoom.sort[0].field,
      };
      axios
        .post("Hotel/GetHotelCustomerServicesListAsync", param)
        .then((response) => {
          this.roomRows = response.data.data;
          this.totalRecords = response.data.totalCount;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-item-group {
  background: #d2e3ff73;
}

.v-expansion-panel-header {
  background: #d2e3ff73;
}

button {
  z-index: 1;
}
</style>
