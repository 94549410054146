<template>
  <v-card>
    <v-toolbar dark color="blue" class="darken-1">
      <v-toolbar-title>
        {{ $t("edit_service") }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="dialogueClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text>
      <v-container>
        <form class="fix-for-nepali-label">
          <v-text-field v-model="editedData.hotelCustomerService"
                        dense
                        outlined>
            <template v-slot:label>
              {{ $t("homestay") + " " + $t("customer_service") }}
            </template>
          </v-text-field>
          <v-text-field
            v-model="editedData.hotelServiceRate"
            :error-messages="hotelServiceRateErrors"
            required
                        dense
                        outlined
            @input="$v.editedData.hotelServiceRate.$touch()"
            @blur="$v.editedData.hotelServiceRate.$touch()"
          >
            <template v-slot:label>
              {{ $t("homestay") + " " + $t("service_rate") }}
            </template>
          </v-text-field>
          <v-text-field v-model="editedData.hotelServiceDescription"
                        dense
                        outlined>
            <template v-slot:label>
              {{ $t("homestay") + " " + $t("service_description") }}
            </template>
          </v-text-field>
          <!-- <v-checkbox v-model="formData.isActive" label="Activate User"></v-checkbox> -->
        </form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn dark color="red darken-1" @click="dialogueClose">{{
        $t("cancel")
      }}</v-btn>
      <v-btn dark color="blue darken-1" @click="submit">{{ $t("save") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import axios from "axios";

export default {
  name: "editServiceForm",
  props: ["success", "editData"],
  computed: {
    HotelCustomerServiceErrors() {
      const errors = [];
      if (!this.$v.editedData.HotelCustomerService.$dirty) return errors;
      !this.$v.editedData.HotelCustomerService.required &&
        errors.push(`${this.$t("name")} ${this.$t("is_required")}`);

      return errors;
    },
    hotelServiceRateErrors() {
      const errors = [];
      if (!this.$v.editedData.hotelServiceRate.$dirty) return errors;
      !this.$v.editedData.hotelServiceRate.required &&
        errors.push(`${this.$t("service_rate")} ${this.$t("is_required")}`);
      return errors;
    },
  },
  watch: {
    updated: {
      handler: function(val) {
        if (this.updated && val) {
          this.updated = false;
          this.$emit("formResponseRoom", this.returnData);
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      returnData: [],
      show: false,
      updated: false,
      editedData: {
        hotelCustomerService: "",
        hotelServiceRate: "",
      },
      servicesData: {
        data: {},
        message: "Cancelled",
        color: "red",
      },
    };
  },
  validations: {
    editedData: {
      hotelCustomerService: { required },
      hotelServiceRate: { required },
    },
  },
  destroyed() {},
  mounted() {
    this.$forceUpdate();
    this.formData();
  },
  methods: {
    dialogueClose() {
      console.log(this.servicesData);
      this.$emit("formResponse", this.servicesData);
    },
    async formData() {
      const response = await axios.get(
        "Hotel/GetHotelCustomerServicesByIDAsync/" + this.editData
      );
      this.editedData = response.data;
      this.updated = false;
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        let param = {
          HotelCustomerServiceID: this.editedData.hotelCustomerServiceID,
          HotelCustomerService: this.editedData.hotelCustomerService,
          hotelServiceRate: parseInt(this.editedData.hotelServiceRate),
          HotelServiceDescription: this.editedData.hotelServiceDescription,
        };
        axios
          .post("Hotel/UpdateHotelCustomerServicesAsync", param)
          .then((response) => {
            if (response.data.success) {
              this.editedData = {};
              this.servicesData.data = response.data;
              this.servicesData.message = "Service Updated Successfully";
              this.servicesData.color = "success";
              this.dialogueClose();
            }
          })
          .catch((response) => {
            this.servicesData.data = response.data;
            this.servicesData.message =
              "Error Adding Service, Please Contact Admin";
            this.servicesData.color = "error";
            this.dialogueClose();
          });
      }
    },
    clear() {
      this.$v.$reset();
      this.editedData.Fare = "";
    },
  },
};
</script>

<style scoped></style>
